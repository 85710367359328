export function scrollMenu() {
	const activeItems = ['.current-cat', '.current-cat-parent', '.current-cat-child', '[aria-current="page"]']
	const scrollMenus = document.querySelectorAll('[data-scroll-menu]')

	if (scrollMenus.length === 0) return

	scrollMenus.forEach(scrollMenu => {
		const scrollMenuActiveItem = scrollMenu?.querySelector(activeItems)

		if (!scrollMenuActiveItem) return

		const paddingLeft = parseInt(window.getComputedStyle(scrollMenu).getPropertyValue('padding-left'))
		scrollMenu.scrollTo({
			top: 0,
			left: scrollMenuActiveItem.offsetLeft - paddingLeft,
		})
	})
}
