const searchPopupToggleEls = document.querySelectorAll('[data-search-popup-toggle]')
const searchPopupEl = document.querySelector('[data-search-popup]')

export function searchPopup() {
	if (searchPopupToggleEls.length === 0 || !searchPopupEl) return

	searchPopupToggleEls.forEach(element => {
		element.addEventListener('click', (e) => {
			const isSearchPopupActive = searchPopupEl.classList.contains('active')

			if (isSearchPopupActive) {
				closeSearchPopup()
			} else {
				openSearchPopup()
			}
			
			e.stopPropagation();
		})
	})

	function openSearchPopup() {
		searchPopupEl.classList.add('active')
		document.addEventListener('click', closeWhenClickedOutside)
		document.addEventListener('keydown', closeWithEsc)
		searchPopupEl.addEventListener('transitionend', focusInput, { once: true })
	}

	function closeSearchPopup() {
		searchPopupEl.classList.remove('active')
		document.removeEventListener('click', closeWhenClickedOutside)
		document.removeEventListener('keydown', closeWithEsc)
	}

	function closeWhenClickedOutside(event) {
		if (!searchPopupEl.contains(event.target) && event.target !== searchPopupToggleEls) {
			closeSearchPopup()
		}
	}

	function focusInput() {
		const searchInput = searchPopupEl.querySelector('input[type="search"]')
		if (searchInput) {
			searchInput.focus()
		}
	}

	function closeWithEsc(event) {
		if (event.key === 'Escape') {
			closeSearchPopup()
		}
	}
}
