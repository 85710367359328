import Slide, { settings } from './slide'

const Tabs = () => {
	let tabDisplayStyle
	const displayCheckTab = document.querySelector(`[data-tab-button].tab--accordion`)
	// return early if no tabs on page
	if (!displayCheckTab) return

	const activeClass = 'tab--active'
	const tabButtons = document.querySelectorAll('[data-tab-button]')

	tabDisplayStyle = window.getComputedStyle(displayCheckTab).display

	// breakpoint lg
	window.matchMedia('(max-width: 1023px)').onchange = () => resizeHandler()

	tabButtons.forEach(tabButton => {
		tabButton.addEventListener('click', () => tabHandler(tabButton))
	})

	function tabHandler(element) {
		const tabButtonID = element.dataset.tabButton
		const tabContainer = element.closest('[data-tabs]')
		const visibleContent = tabContainer.querySelector('.' + settings.classes.show)
		const currentContent = tabContainer.querySelector(`[data-tab-content="${tabButtonID}"]`)
		const currentAccordionButton = tabContainer.querySelector(`[data-tab-button="${tabButtonID}"].tab--accordion`)

		if (tabDisplayStyle === 'none') {
			// desktop tabs
			const activeTabButton = tabContainer.querySelector('.' + activeClass)
			const currentTabButton = tabContainer.querySelector(`[data-tab-button="${tabButtonID}"]`)

			if (element.classList.contains(activeClass) || currentContent.classList.contains(settings.classes.collapsing)) {
				return
			}

			activeTabButton.classList.remove(activeClass)
			currentTabButton.classList.add(activeClass)
			Slide(visibleContent)
			Slide(currentContent)
			return
		}

		// mobile accordion
		Slide(currentContent)
		currentAccordionButton?.classList.toggle(activeClass)
	}

	// 
	function resizeHandler() {
		tabDisplayStyle = window.getComputedStyle(displayCheckTab).display
		const tabContainers = document.querySelectorAll('[data-tabs]')
		tabContainers.forEach(tabContainer => {
			const activeButtons = tabContainer.querySelectorAll('.' + activeClass)
			const activePanels = tabContainer.querySelectorAll('[data-tab-content].element-show')
			const firstButtons = tabContainer.querySelectorAll('[data-tab-button="1"]')
			const firstPanel = tabContainer.querySelector('[data-tab-content="1"]')

			activeButtons.forEach(activeButton => {
				activeButton.classList.remove(activeClass)
			})

			activePanels.forEach(activePanel => {
				activePanel.classList.remove('element-show')
			})

			firstButtons.forEach(firstButton => {
				firstButton.classList.add(activeClass)
			})

			firstPanel.classList.add('element-show')
		})
	}
}

export default Tabs