import Accordion from './modules/accordion'
import Tabs from './modules/tabs'
import OverlayMenu from './modules/overlay-menu'
import Animate from './modules/animate'
import { dialog } from './modules/dialog'
import { searchPopup } from './modules/search-popup'
import { scrollMenu } from './modules/scroll-menu'

Accordion()

Tabs()

OverlayMenu()

Animate()

dialog()

searchPopup()

scrollMenu()

if (document.readyState === 'interactive') document.body.classList.remove('preload')